import { Input } from '@chakra-ui/input';
import { Box, Text } from '@chakra-ui/layout';
import { Button, Center, Image, keyframes, useDisclosure, VStack } from '@chakra-ui/react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { loginErrorTypes } from '../components/atoms/login/errors';
import ForgotPassword from '../components/atoms/login/forgotPassword';
import NewUserPassword from '../components/atoms/login/newUserPassword';
import BaseLayout from '../components/layouts/BaseLayout';
import { cognitoAuthDetails, cognitoUser, isCognitoSessionCookieValid } from '../utils/cognito';

const defaultPostLoginPage = '/dashboard';

const IndexPage = () => {
	const [loginFlags, setLoginFlags] = useState({
		loading: false,
		error: false,
		errorType: loginErrorTypes.NULL,
	});
	const { isOpen: isChangePassOpen, onOpen: onChangePassOpen, onClose: onChangePassClose } = useDisclosure();
	const { isOpen: isForgotPassOpen, onOpen: onForgotPassOpen, onClose: onForgotPassClose } = useDisclosure();

	const userRef = useRef(null);
	const passwordRef = useRef(null);
	const submitRef = useRef(null);
	const router = useRouter();

	const [cred, setCred] = useState({
		email: '',
		password: '',
	});

	const [forgotPasswordStage, setForgotPasswordStage] = useState<'email' | 'code'>('email');

	const [reqAttributes, setReqAttributes] = useState(null);
	const [user, setUser] = useState<CognitoUser | null>(null);
	// let user = null;

	const handleCred = (field) => (event) => {
		setCred((prev) => ({
			...prev,
			[field]: event.target.value,
		}));
	};

	const handleEnter = (field) => (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (field === 'email') {
				passwordRef.current.focus();
			} else {
				submitRef.current.focus();
				submitRef.current.click();
			}
		}
	};

	const handleSuccess = (data?: any) => {
		router.push(defaultPostLoginPage);
	};

	const handleForgotPasswordSuccess = (email) => {
		setCred({
			email: email,
			password: '',
		});
		onForgotPassClose();
		passwordRef.current.focus();
	};

	const handleNewPassword = (userAttributes, requiredAttributes) => {
		delete userAttributes.email_verified;
		setReqAttributes(userAttributes);
		onChangePassOpen();
		setLoginFlags({ loading: false, error: false, errorType: loginErrorTypes.NULL });
	};

	const handleError = (data) => {
		const codeMsg = `${data.code}${data.message ? `: ${data.message}` : ''}`;
		// if (data.code === 'PasswordResetRequiredException') {
		// 	setLoginFlags(prevState => ({
		// 		...prevState,
		// 		loading: false,
		// 		error: false,
		// 		errorType: loginErrorTypes.NULL
		// 	}))
		// 	return handleForgotPassword('code')
		// }

		setLoginFlags({
			loading: false,
			error: true,
			errorType: data
				? codeMsg === 'NotAuthorizedException: Password attempts exceeded'
					? loginErrorTypes.TOO_MANY_ATTEMPT
					: codeMsg === 'NotAuthorizedException: Incorrect username or password.'
					? loginErrorTypes.USER_NOT_AUTHORISED_LOGIN
					: codeMsg === 'UserNotFoundException: User does not exist.'
					? loginErrorTypes.USER_NOT_AUTHORISED_LOGIN
					: data.code
				: loginErrorTypes.UNKNOWN,
		});
		userRef.current.focus();
	};

	const handleLogin = async () => {
		setLoginFlags({
			loading: true,
			error: false,
			errorType: loginErrorTypes.NULL,
		});
		if (cred.email === '' || cred.password === '') {
			return handleError({ code: loginErrorTypes.EMPTY_EMAIL_PASSWORD });
		}

		const userTemp = cognitoUser(cred.email);
		const authDetails = cognitoAuthDetails(cred.email, cred.password);
		userTemp.authenticateUser(authDetails, {
			onSuccess: handleSuccess,
			onFailure: handleError,
			newPasswordRequired: handleNewPassword,
		});
		setUser(userTemp);
	};

	const handleForgotPassword = (type: 'email' | 'code' = 'email') => {
		type ? setForgotPasswordStage(type) : setForgotPasswordStage('email');
		onForgotPassOpen();
	};

	const shake = keyframes`
      0% {
        transform: translateX(0px);
      }
      25% {
        transform: translateX(-15px);
      }
      50% {
        transform: translateX(0px);
      }
      75% {
        transform: translateX(15px);
      }
      100% {
        transform: translateX(0px);
      }
	`;

	return (
		<BaseLayout pageTitle={'Login'}>
			<Box>
				<Center h={'100vh'} w={'100vw'}>
					<Box
						w={'360px'}
						px={'10'}
						py={20}
						bg={'white'}
						borderColor={'gray.50'}
						borderWidth={'1px'}
						borderRadius={'lg'}
						shadow={'md'}
						animation={loginFlags.error ? `${shake} 100ms 2` : null}
					>
						<VStack spacing={10}>
							<VStack spacing={5}>
								<Image w={'100px'} src={'/logo/one-word-gradient.svg'} />
								<Text fontSize={'lg'} fontWeight={'semibold'}>
									Login to Publisher
								</Text>
							</VStack>
							<VStack spacing={4} w={'100%'}>
								<Input
									ref={userRef}
									disabled={loginFlags.loading}
									size={'lg'}
									type={'email'}
									placeholder={'Enter Email ID'}
									value={cred.email}
									onChange={handleCred('email')}
									onKeyPress={handleEnter('email')}
								/>
								<Input
									ref={passwordRef}
									disabled={loginFlags.loading}
									size={'lg'}
									type={'password'}
									placeholder={'Enter Password'}
									value={cred.password}
									onChange={handleCred('password')}
									onKeyPress={handleEnter('password')}
								/>
								<Button
									ref={submitRef}
									size={'lg'}
									colorScheme={'purple'}
									w={'100%'}
									isLoading={loginFlags.loading}
									loadingText={'Logging In'}
									onClick={handleLogin}
								>
									Login
								</Button>
								<Text
									fontSize={'sm'}
									textAlign={'center'}
									fontWeight={'semibold'}
									color={'red.600'}
									visibility={loginFlags.error ? 'visible' : 'hidden'}
								>
									{loginFlags.errorType.message}
								</Text>
								<Button
									variant={'link'}
									size={'xs'}
									disabled={loginFlags.loading}
									onClick={() => handleForgotPassword('email')}
								>
									Forgot Password
								</Button>
							</VStack>
						</VStack>
					</Box>
				</Center>
				{isChangePassOpen && user && (
					<NewUserPassword
						user={user}
						requiredAttributes={reqAttributes}
						isOpen={isChangePassOpen}
						onClose={onChangePassClose}
						onSuccess={handleSuccess}
					/>
				)}
				{isForgotPassOpen && (
					<ForgotPassword
						email={cred.email}
						isOpen={isForgotPassOpen}
						onClose={onForgotPassClose}
						onSuccess={handleForgotPasswordSuccess}
						initialStage={forgotPasswordStage}
					/>
				)}
			</Box>
		</BaseLayout>
	);
};

export async function getServerSideProps({ req }) {
	const session = isCognitoSessionCookieValid(req);
	if (session) {
		return {
			redirect: {
				destination: defaultPostLoginPage,
				permanent: false,
			},
		};
	}
	return {
		props: {},
	};
}

export default IndexPage;
